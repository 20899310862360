import "./App.css";
import Home from "./pages/home/Home";
import './index.css'

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
