import React from "react";
import Slider from "react-slick";
import sliderImg from "../../assets/slider.png";
import leftArrow from "../../assets/step/arrow-left.svg";
import rightArrow from "../../assets/step/arrow-right.svg";
import imgLogo from "../../assets/step/images.png";
import shareImg from "../../assets/step/share.svg";
import LinkedIn from "../../assets/step/linked-in.svg";
// import sliderImg1 from "../../assets/slider1.png";
// import sliderImg2 from "../../assets/slider2.png";
// import sliderImg3 from "../../assets/slider3.png";
// import sliderImg4 from "../../assets/slider4.png";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="right-arrow">
      <img src={rightArrow} alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="left-arrow">
      <img src={leftArrow} alt="" />
    </div>
  );
}

function HomeSlider() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    centerPadding: "270px",
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-content">
        <div className="flex items-start gap-[24px]">
          <img src={imgLogo} className="sm:h-[64px] h-[32px] sm:w-[64px] w-[32px]" alt="" />
          <div>
            <h6 className="font-[Fraunces] font-[600] sm:text-[32px] text-[20px] sm:leading-[34.56px] leading-[21.6px] text-[#242F65] mb-[8px]">
              Karan Agarwal
            </h6>
            <p className="font-[Mulish] font-[500] sm:text-[18px] text-[12px] sm:leading-[23.13px] leading-[15.42px] text-[#525665]">
              Founder @ Homedrop
            </p>
          </div>
        </div>
        <div className="flex gap-[16px]">
          <img src={shareImg} className="sm:h-[64px] h-[32px] sm:w-[64px] w-[32px]" alt="" />
          <img src={LinkedIn} className="sm:h-[64px] h-[32px] sm:w-[64px] w-[32px]" alt="" />
        </div>
      </div>
      <Slider {...settings}>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
        <div>
          <div className="slider-box">
            <img src={sliderImg} alt="" />
          </div>
        </div>
      </Slider>
    </>
  );
}

export default HomeSlider;
