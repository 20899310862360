import React, { useEffect } from "react";
import "./home.css";
import logo from "../../assets/logo.svg";
import nameLogo from "../../assets/name-logo.svg";
import vector1 from "../../assets/item1.svg";
import item2 from "../../assets/step/dot.svg";
import item3 from "../../assets/step/full-dot.svg";
import item1 from "../../assets/step/item1.svg";
import dot from "../../assets/step/item2.svg";
import fullDot from "../../assets/step/item3.svg";
import arrow from "../../assets/step/arrow.svg";
import farmer from "../../assets/step/item4.svg";
import f1 from "../../assets/feature/item1.svg";
import f2 from "../../assets/feature/item2.svg";
import f3 from "../../assets/feature/item3.svg";
import f4 from "../../assets/feature/item4.svg";
import rightArrow from "../../assets/feature/arrow.svg";
import downloadImg from "../../assets/social/download.svg";
import s1 from "../../assets/social/s1.svg";
import s2 from "../../assets/social/s2.svg";
import s3 from "../../assets/social/s3.svg";
import s4 from "../../assets/social/s4.svg";
import s5 from "../../assets/social/s5.svg";
import { Link } from "react-router-dom";
import yashVerma from "../../assets/yash-verma.pdf";
import HomeSlider from "../../components/Slider/HomeSlider";

const featureA = [
  {
    fImg: f1,
  },
  {
    fImg: f2,
  },
  {
    fImg: f3,
  },
  {
    fImg: f4,
  },
];

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const downloadPDF = () => {
    const pdfUrl = yashVerma;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "Yash Verma.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const emailAddress = "Yashh.99.verma@gmail.com"

  const handleMail = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <>
      <div className="hero-section">
        <div className="hero-logo">
          <img src={logo} alt="" />
          <img src={nameLogo} alt="" />
        </div>
        <h6 className="web-title">
          Crafting experiences that inspire, design that resonates, and
          innovation that transforms.
        </h6>
        <p className="hero-text">
          🤘 A <span>Product Designer</span> with 3+ years of experience
        </p>
        <div className="hero-wrap">
          <img src={vector1} alt="" />
          <button className="contact-btn">Contact With Me</button>
        </div>
      </div>
      <div className="about-section">
        <h6 className="web-title">About</h6>
        <p className="about-text">
          With over 3 years of hands-on experience in the dynamic world of
          startups, I thrive on the fast-paced environment and the opportunity
          to make a tangible impact.
        </p>
        <p className="about-my-text">
          My journey as a Product Designer has been shaped by collaborating with
          innovative startups, where I've honed my skills in crafting
          user-centric solutions that drive growth and elevate the user
          experience.
        </p>
        <div className="step-box">
          <div className="flex justify-center relative max-w-[667.62px] w-full m-auto sm:gap-[27px] gap-[48px] flex-col items-center">
            <div className="absolute sm:h-[251.15px] hidden sm:block h-[350px] sm:w-[3.49px] w-[2px] bg-[#E6E6E6] sm:left-[19px] left-[15px] z-1"></div>
            <div className="flex sm:gap-[54px] gap-[12px]">
              <img
                src={fullDot}
                className="relative sm:h-auto h-[30px] sm:w-auto w-[30px] z-10"
                alt=""
              />
              <div className="flex flex-col ">
                <div className="flex sm:gap-[28px] gap-[13px]">
                  <img
                    src={item1}
                    className="h-[48px] sm:h-auto sm:w-auto w-[48px]"
                    alt=""
                  />
                  <div>
                    <h6 className="step-title">
                      Product Designer at
                      <span> Turka Technologies Pvt Ltd </span>
                    </h6>
                    <div className="sm:block hidden">
                      <p className="step-text">
                        Worked on design system with collaboration
                      </p>
                      <p className="step-sub-text">
                        June 2023 - Currently Working
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden block">
                  <p className="step-text">
                    Worked on design system with collaboration
                  </p>
                  <p className="step-sub-text">June 2023 - Currently Working</p>
                </div>
              </div>
            </div>
            <div className="flex sm:gap-[54px] gap-[12px]">
              <img
                src={dot}
                className="relative sm:h-auto h-[30px] sm:w-auto w-[30px] z-10"
                alt=""
              />
              <div className="flex flex-col ">
                <div className="flex sm:gap-[28px] gap-[13px]">
                  <img
                    src={item2}
                    className="h-[48px] sm:h-auto sm:w-auto w-[48px]"
                    alt=""
                  />
                  <div>
                    <h6 className="step-title">
                      Product Designer at
                      <span> Homedrop Services Pvt Ltd </span>
                    </h6>
                    <div className="sm:block hidden">
                      <p className="step-text">
                        Worked on design system with collaboration
                      </p>
                      <p className="step-sub-text">June 2022 - May 2023</p>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden block">
                  <p className="step-text">
                    Worked on design system with collaboration
                  </p>
                  <p className="step-sub-text">June 2022 - May 2023</p>
                </div>
              </div>
            </div>
            <div className="flex sm:gap-[54px] gap-[12px]">
              <img
                src={dot}
                className="relative sm:h-auto h-[30px] sm:w-auto w-[30px] z-10"
                alt=""
              />
              <div className="flex flex-col ">
                <div className="flex sm:gap-[28px] gap-[13px]">
                  <img
                    src={item3}
                    className="h-[48px] sm:h-auto sm:w-auto w-[48px]"
                    alt=""
                  />
                  <div>
                    <h6 className="step-title">
                      Product Designer at
                      <span> Homedrop Services Pvt Ltd</span>
                    </h6>
                    <div className="sm:block hidden">
                      <p className="step-text">
                        Worked on design system with collaboration
                      </p>
                      <p className="step-sub-text">May 2021 - April 2022</p>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden block">
                  <p className="step-text">
                    Worked on design system with collaboration
                  </p>
                  <p className="step-sub-text">May 2021 - April 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="if-section">
        <div className="flex gap-[70px] sm:gap-[180px] flex-col sm:flex-row items-center">
          <div className="w-full">
            <p className="if-title">If You have ever need</p>
            <p className="if-title sm:ml-[20px] ml-0 pt-[10px]">
              help to repair web-app...
            </p>
            <img src={arrow} alt="" />
            <div className="relative">
              <p className="if-text">
                I’ll be available here with my tool kit :
              </p>
            </div>
          </div>
          <div className="w-full">
            <img src={farmer} alt="" />
          </div>
        </div>
      </div>
      <div className="some-section">
        <h6 className="web-title pb-[64px]">Here are some testimonials...</h6>
        <div className="some-slider">
          <HomeSlider />
        </div>
      </div>
      <div className="feature-section">
        <h6 className="web-title sm:pb-[64px] pb-[33px] sm:pl-0 pl-[26px] ">
          Featured Projects
        </h6>
        <div className="flex flex-col sm:gap-[64px] gap-[18px]">
          {featureA?.map((e, i) => {
            return (
              <>
                <Link to="/about">
                  <div
                    key={i}
                    className="border-2 border-[#0000001A] flex flex-col gap-[32px] bg-white sm:p-[32px] p-[8px] sm:rounded-[48px] rounded-[18px]"
                  >
                    <img src={e?.fImg} alt="" />
                    <button className="flex sm:gap-[20px] gap-[12px] font-[Inter] font-[400] sm:text-[26.27px] text-[18px] text-[#474747] justify-center items-center border-2 rounded-[36px] py-[20px]">
                      View Case Study <img src={rightArrow} alt="" />
                    </button>
                  </div>
                </Link>
              </>
            );
          })}
        </div>
      </div>
      <div className="footer">
        <h6 className="web-title sm:pb-[64px] pb-[20px] !sm:px-0 !px-[30px] !sm:text-left !text-center !max-w-[1033px]">
          Ready to collaborate or have any questions? Feel free to reach out!
        </h6>
        <p className="footer-text">
          "Designing <span>Experiences, Shaping</span> Futures"
        </p>
        <div className="flex items-center justify-center sm:pb-[135px] pb-[57px]">
          <button
            onClick={downloadPDF}
            className="flex cursor-pointer border-[0.5px] border-[#000000] bg-[#FFFFFF] rounded-[12px] sm:px-[51px] px-[16px] sm:py-[20px] py-[12px] items-center gap-[10px]"
          >
            <img src={downloadImg} alt="" /> Download My CV!
          </button>
        </div>
        <div className="flex sm:flex-row flex-col-reverse pb-[28px] sm:mx-[60px] mx-[43px] justify-between items-center">
          <div className="flex sm:flex-row flex-col items-center gap-[38px]">
            <img
              src={logo}
              className="h-[83px] w-[127.27px] object-cover"
              alt=""
            />
            <p className="font-[Inter] font-[400] sm:text-[20px] text-[12px] sm:leading-[24.2px] leading-[16.94px] text-[#242F65] m-0">
              © 2024, All Rights Reserved, Design by{" "}
              <span className="font-[700] text-[#FF9270]">Yash Verma</span>
            </p>
          </div>
          <div className="flex sm:mb-0 mb-[73px] gap-[10px]">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://medium.com/@yverma21"
            >
              <img src={s1} alt="" />
            </Link>
            <Link
              onClick={handleMail}
              target="_blank"
              rel="noopener noreferrer"
              to="Yashh.99.verma@gmail.com"
            >
              <img src={s4} alt="" />
            </Link>
            <Link
              onClick={handleMail}
              target="_blank"
              rel="noopener noreferrer"
              to="Yashh.99.verma@gmail.com"
            >
              <img src={s5} alt="" />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.behance.net/yashverma43"
            >
              <img src={s2} alt="" />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.linkedin.com/in/yash-verma-36740b177/"
            >
              <img src={s3} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
