import React, { useEffect } from "react";
import aboutLogo from "../../assets/about-logo.svg";
import aboutCenter from "../../assets/about-center.svg";
import img1 from "../../assets/feature/img1.png";
import aboutImg from "../../assets/card/img1.png";
import aboutImg1 from "../../assets/card/img2.png";
import bookImg from "../../assets/card/bookmark.svg";
import lImg from "../../assets/card/l.svg";
import instagram from "../../assets/card/instagram.svg";
import map from "../../assets/card/map-pin.svg";
import info from "../../assets/card/info.svg";
import our1 from "../../assets/our/our1.svg";
import our2 from "../../assets/our/our2.svg";
import our3 from "../../assets/our/our3.svg";
import our4 from "../../assets/our/our4.svg";
import r1 from "../../assets/our/r1.svg";
import r2 from "../../assets/our/r2.svg";
import r3 from "../../assets/our/r3.svg";
import item1 from "../../assets/card/item1.svg";
import item2 from "../../assets/card/item2.svg";
import item3 from "../../assets/card/item3.svg";
import item4 from "../../assets/card/item4.svg";
import item5 from "../../assets/card/item5.svg";
import item6 from "../../assets/card/item6.svg";
import item7 from "../../assets/user/Ellipse 1108.svg";
import social1 from "../../assets/user/Icon1.svg";
import social2 from "../../assets/user/Icon2.svg";
import social3 from "../../assets/user/Icon3.svg";
import social4 from "../../assets/user/Icon4.svg";
import user1 from "../../assets/user/user1.svg";
import user2 from "../../assets/user/user2.svg";
import screen1 from "../../assets/user/screen1.png";
import screen2 from "../../assets/user/screen2.png";
import screen3 from "../../assets/user/screen3.png";
import screen4 from "../../assets/screen/item1.png";
import screen5 from "../../assets/screen/item2.png";
import screen6 from "../../assets/screen/item3.png";
import screen7 from "../../assets/screen/item4.png";
import screen8 from "../../assets/screen/item5.png";
import screen9 from "../../assets/screen/item6.png";
import screen10 from "../../assets/screen/item7.png";
import screen11 from "../../assets/screen/item8.png";
import screen12 from "../../assets/screen/item9.png";
import screen13 from "../../assets/screen/item10.png";
import screen14 from "../../assets/screen/item16.png";
import iPhone1 from "../../assets/screen/iPhone1.png";
import iPhone2 from "../../assets/screen/iPhone2.png";
import iPhone3 from "../../assets/screen/iPhone3.png";
import wrap from "../../assets/screen/wrap.svg";

function AboutPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="about-page">
        <div className="hero-logo">
          <img src={aboutLogo} alt="" />
        </div>
        <div className="flex absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] items-center justify-center flex-col max-w-[974px] w-full m-auto">
          <img src={aboutCenter} alt="" />
          <p className="font-[Inter] font-[600] sm:text-[32px] text-[20px] sm:px-0 px-[20px] sm:leading-[38.73px] leading-[28px] text-center text-[#FFFFFF] uppercase pt-[25px] pb-[29px]">
            we make the influencer marketing journey smooth and efficient for
            them.
          </p>
          <div className="flex flex-col sm:flex-row sm:gap-[32px] gap-[12px]">
            <button className="bg-[#0000007A] py-[10px] px-[24px]  w-[197px] rounded-[8px] text-[#F4CF11] font-[Source Sans 3] font-[600]">
              Product Designer
            </button>
            <button className="bg-[#0000007A] py-[10px] px-[24px] w-[197px] rounded-[8px] text-[#F4CF11] font-[Source Sans 3] font-[600]">
              January 2024
            </button>
          </div>
        </div>
      </div>
      <div className="project-section">
        <h6 className="web-title sm:pl-0 pl-[30px] ">Project Overview</h6>
        <div className="grid items-center sm:gap-[208px] gap-[20px] px-[14px] sm:px-[90px] sm:mt-[48px] mt-[22px] grid-cols-1 sm:grid-cols-2">
          <p className="text-justify font-[Inter] font-[400] sm:text-[32px] text-[18px] sm:leading-[52px] leading-[24px] text-[#000000]">
            CYBEES is a software solution designed to streamline the influencer
            marketing journey for both influencers and brands. By providing a
            platform that facilitates seamless collaboration, communication, and
            campaign management, CYBEES aims to make influencer marketing more
            efficient and effective.
          </p>
          <div className="relative">
            <div
              style={{ boxShadow: "0px 0px 16.4px 0px #626D9440" }}
              className="bg-[#FFFFFF] absolute py-[6px] px-[20px] rounded-[5.97px] right-[84px] top-[-16px] "
            >
              <div className="flex gap-[12px]">
                <img src={aboutImg} alt="" />
                <div>
                  <h6 className="text-[#333333] font-[Poppins] font-[600] text-[10.45px] leading-[15.67px] ">
                    Jane Copper
                  </h6>
                  <div className="flex gap-[11px] flex-wrap">
                    <div className="flex gap-[4px]">
                      <img src={instagram} alt="" />
                      <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                        @johndoe
                      </p>
                    </div>
                    <div className="flex gap-[4px]">
                      <img src={map} alt="" />
                      <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                        Delhi
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-[4px] gap-[4px]">
                    <img src={info} alt="" />
                    <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                      Food, lifestyle
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex mt-[9.8px] gap-[6.72px]">
                <div className="bg-[#F8F8F8] px-[12px] py-[4.5px] rounded-[5.97px]">
                  <h6 className="font-[Poppins] m-0 text-center font-[500] text-[10.45px] leading-[15.67px] text-[#333333]">
                    52K
                  </h6>
                  <p className="font-[Poppins] font-[400] text-center text-[7.46px] leading-[11.2px] text-[#333333]">
                    Followers
                  </p>
                </div>
                <div className="bg-[#F8F8F8] px-[12px] py-[4.5px] rounded-[5.97px]">
                  <h6 className="font-[Poppins] m-0 text-center font-[500] text-[10.45px] leading-[15.67px] text-[#333333]">
                    20K
                  </h6>
                  <p className="font-[Poppins] font-[400] text-center text-[7.46px] leading-[11.2px] text-[#333333]">
                    Reach
                  </p>
                </div>
                <div className="bg-[#F8F8F8] px-[12px] py-[4.5px] rounded-[5.97px]">
                  <h6 className="font-[Poppins] m-0 text-center font-[500] text-[10.45px] leading-[15.67px] text-[#333333]">
                    2%
                  </h6>
                  <p className="font-[Poppins] font-[400] text-center text-[7.46px] leading-[11.2px] text-[#333333]">
                    Engagement
                  </p>
                </div>
              </div>
            </div>
            <img src={img1} alt="" />
            <div
              style={{ boxShadow: "0px 0px 16.4px 0px #626D9440" }}
              className="bg-[#FFFFFF] absolute py-[6px] px-[20px] rounded-[5.97px] left-[-23px] bottom-[-16px] "
            >
              <div className="flex gap-[12px]">
                <img src={aboutImg1} alt="" />
                <div>
                  <div className="flex justify-between items-center">
                    <h6 className="text-[#333333] font-[Poppins] font-[600] text-[10.45px] leading-[15.67px] ">
                      Campaign name
                    </h6>
                    <img src={bookImg} alt="" />
                  </div>
                  <h6 className="text-[#484848] mb-[8px] font-[Poppins] font-[600] text-[10.45px] leading-[15.67px] ">
                    Starbucks
                  </h6>
                  <div className="flex gap-[11px] flex-wrap">
                    <div className="flex gap-[4px]">
                      <img src={instagram} alt="" />
                      <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                        @Instagram
                      </p>
                    </div>
                    <div className="flex gap-[4px]">
                      <img src={lImg} alt="" />
                      <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                        16 Dec 2021
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-[4px] gap-[4px]">
                    <img src={info} alt="" />
                    <p className="text-[#666666] font-[Poppins] font-[400] text-[8.96px] leading-[11.94px]">
                      Food, lifestyle
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="if-page">
        <div className="problem-box">
          <h6 className="web-title !sm:text-left !text-center">Problem Statement</h6>
          <p className="text-center font-[Inter] font-[400] sm:text-[32px] text-[24px] sm:leading-[52px] leading-[30px] text-[#282D46] mt-[13px] max-w-[1040px] m-auto w-full">
            Influencer marketing has become a powerful tool for brands to reach
            their target audience and drive engagement. However, the process of
            identifying, contacting, and collaborating with influencers can be
            time-consuming and challenging for brands. Likewise, influencers
            often struggle to connect with the right brands and manage their
            partnerships effectively.
          </p>
          <div className="wrap1"></div>
          <div className="wrap2"></div>
          <div className="wrap3"></div>
          <div className="wrap4"></div>
        </div>
      </div>
      <div className="our-section">
        <h6 className="web-title !sm:text-left !text-center pb-[48px]">Our Process</h6>
        <div className="flex sm:flex-row flex-col sm:gap-0 gap-[30px] items-center justify-between">
          <div className="flex flex-col items-center sm:gap-[26px] gap-[14px]">
            <img src={our1} alt="" />
            <p className="text-[#242F65] font-[Inter] font-[500] text-[32px] leading-[37.46px] m-0">
              Discover
            </p>
          </div>
          <div className="flex flex-col items-center sm:gap-[26px] gap-[14px]">
            <img src={our2} alt="" />
            <p className="text-[#242F65] font-[Inter] font-[500] text-[32px] leading-[37.46px] m-0">
              Define
            </p>
          </div>
          <div className="flex flex-col items-center sm:gap-[26px] gap-[14px]">
            <img src={our3} alt="" />
            <p className="text-[#242F65] font-[Inter] font-[500] text-[32px] leading-[37.46px] m-0">
              Ideate
            </p>
          </div>
          <div className="flex flex-col items-center sm:gap-[26px] gap-[14px]">
            <img src={our4} alt="" />
            <p className="text-[#242F65] font-[Inter] font-[500] text-[32px] leading-[37.46px] m-0">
              Design
            </p>
          </div>
        </div>
        <div className="sm:pt-[120px] pt-[60px]">
          <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] text-[#242F65] pb-[48px]">
            Research & Analysis
          </h6>
          <div className="flex flex-col gap-[64px]">
            <div className="flex sm:flex-row flex-col r-card items-center sm:gap-[40px] gap-[20px]">
              <img src={r1} className="h-[80px] w-[80px]" alt="" />
              <p className="font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:text-left text-center sm:leading-[38.73px] leading-[32px] text-[#282D46] sm:px-0 px-[20px]">
                Conducted interviews and surveys with both influencers and
                brands to understand their pain points, needs, and preferences
                in influencer marketing.
              </p>
            </div>
            <div className="flex sm:flex-row flex-col r-card items-center sm:gap-[40px] gap-[20px]">
              <img src={r3} className="h-[80px] w-[80px]" alt="" />
              <p className="font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:text-left text-center sm:leading-[38.73px] leading-[32px] text-[#282D46] sm:px-0 px-[20px]">
                Analyzed the existing influencer marketing landscape and
                identified inefficiencies and gaps in the process.
              </p>
            </div>
            <div className="flex sm:flex-row flex-col r-card items-center sm:gap-[40px] gap-[20px]">
              <img src={r2} className="h-[80px] w-[80px]" alt="" />
              <p className="font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:text-left text-center sm:leading-[38.73px] leading-[32px] text-[#282D46] sm:px-0 px-[20px]">
                Synthesized research findings to uncover key insights and
                opportunities for improvement in influencer-brand collaboration.
              </p>
            </div>
          </div>
        </div>
        <div className="sm:pt-[120px] pt-[60px]">
          <div className="flex flex-col sm:gap-[120px] gap-[40px]">
            <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
              <img src={item1} className="object-cover" alt="item1" />
              <div>
                <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] sm:mt-0 mt-[20px] leading-[59.18px] text-[#242F65] sm:mb-[48px] mb-[24px]">
                  Business Challenge
                </h6>
                <p className="font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:text-left text-center sm:leading-[38.73px] leading-[32px] text-[#282D46] sm:px-0 px-[20px]">
                  The primary business challenge addressed by CYBEES is the
                  inefficiency and complexity of the influencer marketing
                  process. Brands often struggle to find the right influencers
                  for their campaigns, while influencers face difficulties in
                  connecting with brands that align with their values and
                  audience demographics. CYBEES aims to overcome these
                  challenges by providing a centralized platform that
                  facilitates seamless collaboration and communication between
                  influencers and brands.
                </p>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
              <div>
                <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] sm:mt-0 mt-[20px] leading-[59.18px] text-[#242F65] sm:mb-[48px] mb-[24px]">
                  Product User
                </h6>
                <p className="m-0 pb-[24px] font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]">Influencers: </span> Individuals
                  or content creators who have a following on social media
                  platforms and collaborate with brands for sponsored content
                  and partnerships.
                </p>
                <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]"> Brands:</span> Companies or
                  organizations that leverage influencer marketing as part of
                  their marketing strategy to reach their target audience and
                  drive engagement.
                </p>
              </div>
              <img src={item2} className="object-cover" alt="item1" />
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
              <img src={item3} className="object-cover" alt="item1" />
              <div>
                <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] sm:mt-0 mt-[20px] leading-[59.18px] text-[#242F65] mb-[48px]">
                  Quantitative Research
                </h6>
                <p className="m-0 pb-[24px] font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]">Surveys:</span> Gathering data
                  from a large sample of influencers and brands to quantify
                  their preferences, pain points, and behaviors related to
                  influencer marketing.
                </p>
                <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]"> Analytics:</span> Analyzing
                  metrics such as engagement rates, conversion rates, and ROI
                  from past influencer marketing campaigns to identify trends
                  and patterns.
                </p>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
              <div>
                <h6 className="font-[Fraunces] font-[600]sm:text-[48px] text-[30px] sm:mt-0 mt-[20px] leading-[59.18px] text-[#242F65] mb-[48px]">
                  User Needs
                </h6>
                <p className="m-0 pb-[24px] font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]"> For Influencers:</span> Access
                  to a diverse range of collaboration opportunities, tools for
                  managing partnerships and content, and insights into campaign
                  performance.
                </p>
                <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                  <span className="font-[700]"> For Brands:</span> Simplified
                  processes for identifying and contacting influencers, robust
                  campaign management tools, and comprehensive analytics for
                  tracking campaign performance and ROI.
                </p>
              </div>
              <div className="flex justify-end">
                <img src={item4} className="object-cover " alt="item1" />
              </div>
            </div>
            <div>
              <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] text-center leading-[59.18px] text-[#242F65] mb-[48px]">
                Competitors Analysis
              </h6>
              <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
                <div>
                  <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                    Competitor 1
                  </h6>
                  <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify pb-[48px] text-[#242F65]">
                    Rewardstyle, Inc., doing business as LTK, is a leading
                    influencer technology platform. The Company focuses on sales
                    by uniting brands, creators, and shoppers through the power
                    of distributed original content by using applications,
                    websites, newsletters, and social platforms.
                  </p>
                  <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                    Features:
                  </h6>
                  <ul className="flex flex-col list-disc ml-[24px] gap-[16px]">
                    <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      The Genesis
                    </li>
                    <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      Platform Dynamics
                    </li>
                    <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      Brand Collaboration Opportunities
                    </li>
                    <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      Real-Time Analytics
                    </li>
                  </ul>
                </div>
                <div className="flex justify-end">
                  <img src={item5} className="object-cover" alt="item1" />
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
              <div className="flex justify-start">
                <img src={item6} className="object-cover" alt="item1" />
              </div>
              <div>
                <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                  Competitor 2
                </h6>
                <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify pb-[48px] text-[#242F65]">
                  Lionize is a SaaS platform that helps brands, agencies, and
                  social media teams manage every aspect of the Influencer
                  Marketing lifecycle. Our system recruits, manages, and reports
                  Influencer talent for you.
                </p>
                <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                  Features:
                </h6>
                <ul className="flex flex-col list-disc ml-[24px] gap-[16px]">
                  <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                    Recruitment
                  </li>
                  <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                    Selection
                  </li>
                  <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                    Management
                  </li>
                  <li className="font-[Inter] font-[700] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                    Reporting
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] text-center leading-[59.18px] text-[#242F65] sm:mb-[48px] mb-[20px]">
                User Persona
              </h6>
              <div className="grid grid-cols-1 items-center">
                <div className="grid sm:grid-cols-2 grid-cols-1 items-center">
                  <div>
                    <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                      Description
                    </h6>
                    <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify pb-[48px] text-[#242F65]">
                      <span className="font-[600]">Bhushan Pungliya </span> is a
                      young and dynamic entrepreneur with a passion for
                      technology and innovation. As the CEO of &nbsp;
                      <span className="font-[600] underline">
                        Varlyq Technologies,
                      </span>
                      &nbsp;Bhushan is responsible for leading the company's
                      strategic direction, managing operations, and ensuring the
                      delivery of high-quality solutions to clients.
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={item7}
                      className="h-[148px] w-[148px] object-cover"
                      alt="item1"
                    />
                    <h6 className="m-0 pt-[18px] font-[Fraunces] font-[600] text-[26px] leading-[47.48px] text-center text-[#282D46]">
                      Bhushan Pungliya
                    </h6>
                    <p className="font-[Poppins] font-[400] text-[26px] text-center text-[#188AEC] m-0 pb-[34px] leading-[47.48px]">
                      CEO- Varlyq Technologies Pvt Ltd
                    </p>
                    <div className="grid grid-cols-2 gap-[12px]">
                      <div className="flex gap-[13px] items-center">
                        <img src={social1} alt="" />
                        <p className="text-[#282D46] font-[Poppins] font-[500] text-[22px] leading-[38.08px]">
                          25
                        </p>
                      </div>
                      <div className="flex gap-[13px] items-center">
                        <img src={social2} alt="" />
                        <p className="text-[#282D46] font-[Poppins] font-[500] text-[22px] leading-[38.08px]">
                          Indore, MP
                        </p>
                      </div>
                      <div className="flex gap-[13px] items-center">
                        <img src={social3} alt="" />
                        <p className="text-[#282D46] font-[Poppins] font-[500] text-[22px] leading-[38.08px]">
                          B.Tech
                        </p>
                      </div>
                      <div className="flex gap-[13px] items-center">
                        <img src={social4} alt="" />
                        <p className="text-[#282D46] font-[Poppins] font-[500] text-[22px] leading-[38.08px]">
                          CEO
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-[38px] grid-cols-1 items-center">
                  <div>
                    <h6 className="font-[Fraunces] font-[600] sm:text-[40px] text-[28px] leading-[57.6px] text-[#242F65] mb-[32px]">
                      A day in their life:
                    </h6>
                    <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      <span className="font-[600]">Time Management:</span>
                      Balancing the demands of managing clients, overseeing
                      projects, and leading the company can be challenging for
                      Bhushan. He must effectively prioritize tasks and allocate
                      time wisely to ensure that all responsibilities are met.
                    </p>
                  </div>
                  <div>
                    <h6 className="font-[Fraunces] sm:block hidden font-[600] text-[40px] leading-[57.6px] text-[#242F65] mb-[32px]">
                      &nbsp;
                    </h6>
                    <p className="m-0 font-[Inter] font-[400] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-justify text-[#242F65]">
                      <span className="font-[600]">Client Expectations:</span>
                      Meeting and exceeding client expectations can be
                      demanding, especially in a competitive industry like
                      technology. Bhushan must consistently deliver high-quality
                      work that meets the unique needs and requirements of each
                      client.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:pl-[75px] pl-0">
              <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] text-left leading-[59.18px] text-[#282D46] sm:mb-[81px] mb-[30px]">
                Taskflows
              </h6>
              <p className="font-[Fraunces] font-[600] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-[#282D46] mb-[24px]">
                Scenario 1 :
              </p>
              <p className="font-[Inter] font-[500] sm:text-[26px] text-[20px] leading-[47.48px] text-[#282D46] sm:pb-[106px] pb-[30px]">
                Influencer-Brand Collaboration on CYBEES
              </p>
              <div className="flex items-center justify-center">
                <img src={user1} className="sm:pb-[265px] pb-[40px]" alt="" />
              </div>
              <p className="font-[Fraunces] font-[600] sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] text-[#282D46] mb-[24px]">
                Scenario 2 :
              </p>
              <p className="font-[Inter] font-[500 sm:text-[26px] text-[20px] leading-[47.48px] text-[#282D46] sm:pb-[106px] pb-[30px]">
                Influencer Responding to Collaboration Request
              </p>
              <div className="flex items-center justify-center">
                <img src={user2} className="sm:pb-[265px] pb-[40px]" alt="" />
              </div>
              <h6 className="font-[Fraunces] sm:text-[48px] text-[30px] leading-[59.18px] text-[#242F65] sm:pb-[200px] pb-[50px] font-[600]">
                Major Screens
              </h6>
              <div className="sm:pb-[200px] pb-[60px]">
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center font-[Fraunces] font-[600]sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] sm:pr-[120px] pr-0 sm:pb-[80px] pb-[40px]">
                    Home Screen
                  </p>
                  <img src={screen1} alt="" />
                </div>
              </div>
              <div className="sm:pb-[200px] pb-[60px]">
                <div className="flex justify-center flex-col items-center">
                  <p className="text-center font-[Fraunces] font-[600]sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] sm:pr-[120px] pr-0 sm:pb-[80px] pb-[40px]">
                    Sponsored Posts
                  </p>
                  <img src={screen2} alt="" />
                </div>
              </div>
              <div className="sm:pb-[200px] pb-[60px]">
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center font-[Fraunces] font-[600]sm:text-[32px] text-[26px] sm:leading-[38.73px] leading-[32px] sm:pr-[120px] pr-0 sm:pb-[80px] pb-[40px]">
                    Campaign
                  </p>
                  <img src={screen3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative overflow-hidden z-10 sm:px-[146px] px-[14px]"
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 26.41%, #FFFFFF 88.4%, #E8FA71 100%)",
        }}
      >
        <h6 className="font-[Fraunces] font-[600] sm:text-[48px] text-[30px] leading-[59.18px] text-[#242F65] sm:pb-[125px] pb-[50px]">
          Screens
        </h6>
        <div className="grid sm:grid-cols-3 grid-cols-2 items-center justify-center sm:gap-[90px] gap-[10px]">
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen4} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Splash
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen5} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Onboarding
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen6} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Login
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={iPhone3} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Search View
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={iPhone1} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Home
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={iPhone2} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Profile View
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen7} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Sponsored Posts
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen8} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Influencers
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen9} alt="" />
            <h6 className="text-[#6A7D8E] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              My Plans
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen10} alt="" />
            <h6 className="text-[#6A7D8E] sm:h-[95px] h-auto font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Campaign Details
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen11} alt="" />
            <h6 className="text-[#6A7D8E] sm:h-[95px] h-[40px] font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Campaign Status
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen12} alt="" />
            <h6 className="text-[#6A7D8E] sm:h-[95px] h-[40px] max-w-[313px] w-full text-center font-[Poppins] font-[500]sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Chat between brand & Influencer
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen13} alt="" />
            <h6 className="text-[#6A7D8E] sm:h-[95px] h-[40px] max-w-[313px] w-full text-center font-[Poppins] font-[500] sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Post Published
            </h6>
          </div>
          <div className="flex flex-col items-center sm:gap-[44px] gap-[14px]">
            <img src={screen14} alt="" />
            <h6 className="text-[#6A7D8E] sm:h-[95px] h-[40px] max-w-[313px] w-full text-center font-[Poppins] font-[500]sm:text-[26px] text-[14px] sm:leading-[47.48px] leading-[22px]">
              Status
            </h6>
          </div>
        </div>
        <div className="flex overflow-hidden items-center sm:py-[368px] py-[150px] justify-center">
          <p className="font-[Fraunces] relative font-[600] sm:text-[114px] text-[40px] sm:leading-[140.56px] leading-[60px] text-center text-[#282D46]">
          <img src={wrap} className="absolute right-[-18%] top-[10px] sm:h-auto h-[80px] sm:w-auto w-[80px] translate-x-[0%] translate-y-[-50%] " alt="" />
            Thank You <br />
            For Watching
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
